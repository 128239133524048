import { useEffect, useState, useRef } from 'react'

// valid -> !errors
// invalid, but not showing errors YET -> feedback=false, !!errors
// invalid, AND showing errors -> feedback=true, !!errors

const never = () => ''
const always = () => true

export type ValidatedFieldType<K = string> = {
  value: K,
  setValue: (newValue: K) => void,
  issues: string,
  showIssues: string | boolean,
  ref: React.RefObject<HTMLInputElement>,
}

export function useValidatedField<K = string>(
  initial: K,
  errors: (v:K) => string = never,
  showFeedback: (v:K) => boolean = always,
): ValidatedFieldType<K> {
  const [value, setValue] = useState<K>(initial)
  const [issues, setIssues] = useState<string>(errors(value))
  const [showIssues, setShowIssues] = useState<string | boolean>(showFeedback(value) ? issues : '')
  const ref = useRef<HTMLInputElement>(null)
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setValue(initial)
  }, [initial, setValue])

  useEffect(() => {
    function checkErrors() {
      const newErrorValues = errors(value)
      if (isMounted.current) {
        setIssues(newErrorValues)
      }
    }
    checkErrors()
  }, [value, setIssues, errors])

  useEffect(() => {
    setShowIssues(showFeedback(value) ? issues : '')
  }, [value, setShowIssues, showFeedback, issues])

  const setter = (newValue: K) => {
    if (isMounted.current) {
      setValue(newValue)
    }
  }

  return {
    value, setValue: setter, issues, showIssues, ref,
  }
}

export default useValidatedField
